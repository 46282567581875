
<script setup lang="ts">
    import { ref } from "vue";
    import { OActionList, OActionItem } from "o365-ui-components";

    export interface IProps {
        xAxis: string;
        yAxis: string;
        a: Array<number>;
        b: Array<number>;
        bind: Function;
    };

    const props = defineProps<IProps>();

    const grid = [
        [0, 2, 2, 2, 2],
        [0, 0, 2, 2, 2],
        [1, 0, 0, 2, 2],
        [1, 1, 0, 0, 2],
        [1, 1, 1, 0, 0],
    ];

    function getStyle(num: number) {
        const style: any = {};
        style.width = "2rem";
        style.height = "2rem";
        style.cursor = "pointer";
        if (num === 0) {
            style.backgroundColor = "#F0E68C";
        } else if (num === 1) {
            style.backgroundColor = "#8FBC8F";
        } else if (num === 2) {
            style.backgroundColor = "#FF6347";
        }
        return style;
    }

    function setA(x: number, y: number) {
        props.bind([x, y], props.b);
    }

    function setB(x: number, y: number) {
        props.bind(props.a, [x, y]);
    }
</script>

<template>
    <!-- <ScopeMatrix :a="[ row.BeforeProbability, row.BeforeConsequence ]" :b="[ row.afterProbability, row.afterConsequence ]" /> -->
    <!-- <ScopeMatrix -->
        <!-- :beforeProbability="row.beforeProbability" -->
        <!-- :beforeConsequence="row.beforeConsequence" -->
        <!-- :afterProbability="row.afterProbability" -->
        <!-- :afterConsequence="row.afterConsequence" -->
    <!-- /> -->
    <!-- <ScopeMatrix -->
        <!-- :before="[ row.BeforeProbability, row.BeforeConsequence ]" -->
        <!-- :after="[ row.afterProbability, row.afterConsequence ]" -->
        <!-- :bind="(before, after) => { -->
            <!-- row.BeforeProbability = before[0]; -->
            <!-- row.BeforeConsequence = before[1]; -->
            <!-- row.AfterProbability = after[0]; -->
            <!-- row.AfterConsequence = after[1]; -->
        <!-- }" -->
    <!-- /> -->
    <div class="p-3">
        <div class="d-flex" style="padding-top: 1.75rem; padding-left: 1rem; padding-bottom: 1.75rem; padding-right: 1rem;">
            <div class="d-flex flex-column" style="padding: 1px; gap: 1px; background-color: #D3D3D3;">
                <template v-for="(row, y) in grid">
                    <div class="d-flex" style="gap: 1px;">
                        <template v-for="(num, x) in row">
                            <OActionList>
                                <template #target="{ target }">
                                    <a
                                        :ref="target"
                                        class="d-flex justify-content-center align-items-center position-relative text-black"
                                        :style="getStyle(num)"
                                    >
                                        <!-- left -->
                                        <template v-if="x === 0">
                                            <div style="position: absolute; left: -1rem; top: 50%; transform: translate(0%, -50%);">
                                                {{ 5 - y }}
                                            </div>
                                        </template>

                                        <!-- top -->
                                        <template v-if="y === 0">
                                            <div style="position: absolute; top: -1.75rem; left: 50%; transform: translate(-50%, 0%);">
                                                {{ x + 1 }}
                                            </div>
                                        </template>
                                        
                                        <!-- bottom -->
                                        <template v-if="x === 2 && y === 4">
                                            <div style="position: absolute; bottom: -1.75rem; left: 50%; transform: translate(-50%, 0%);">
                                                {{ yAxis }}
                                            </div>
                                        </template>
                                        
                                        <!-- right -->
                                        <template v-if="x === 4 && y === 2">
                                            <div style="position: absolute; right: -3.5rem; left: 50%; transform: translate(0%, 0%) rotate(90deg);">
                                                {{ xAxis }}
                                            </div>
                                        </template>

                                        <!-- A -->
                                        <template v-if="x === a[0] && y === (4 - a[1])">
                                            <div class="underline">A</div>
                                        </template>

                                        <!-- B -->
                                        <template v-if="x === b[0] && y === (4 - b[1])">
                                            <div>B</div>
                                        </template>
                                    </a>
                                </template>
                                <OActionItem :text="$t('Probability And Consequence')" style="font-size: 0.85rem;" disabled/>
                                <OActionItem :text="$t('Set Assessment Before Action')" @click="setB(x, 4 - y)" />
                                <OActionItem :text="$t('Set Assessment After Action')" @click="setA(x, 4 - y)" />
                            </OActionList>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
